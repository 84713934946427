@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-slide > div {
    margin: 0 10px;
    
}

.slick-initialized .slick-slide{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bgcolor{
    background-color: #075c8d;
  }